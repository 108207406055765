var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "settings-subsection-parent";
},"3":function(container,depth0,helpers,partials,data) {
    return "subsection-parent";
},"5":function(container,depth0,helpers,partials,data) {
    return "class=\"control-label-disabled tippy-zulip-tooltip\" data-tooltip-template-id=\"mobile-push-notification-tooltip-template\"";
},"7":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <tr>\n                        <td>"
    + container.escapeExpression(container.lambda(container.strict(depth0, "label", {"start":{"line":32,"column":31},"end":{"line":32,"column":41}} ), depth0))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"notification_settings"),{"name":"each","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":33,"column":24},"end":{"line":40,"column":33}}})) != null ? stack1 : "")
    + "                    </tr>\n";
},"8":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                            "
    + ((stack1 = container.invokePartial(require("./notification_settings_checkboxes.hbs"),depth0,{"name":"notification_settings_checkboxes","hash":{"prefix":lookupProperty(depths[2],"prefix"),"is_mobile_checkbox":lookupProperty(depth0,"is_mobile_checkbox"),"is_disabled":lookupProperty(depth0,"is_disabled"),"is_checked":lookupProperty(depth0,"is_checked"),"setting_name":lookupProperty(depth0,"setting_name")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "            <tbody id=\"stream-specific-notify-table\">\n            </tbody>\n";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    You will automatically follow topics that you have configured to both <z-follow>follow</z-follow> and <z-unmute>unmute</z-unmute>.\n                    \n                    \n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/follow-a-topic\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/mute-a-topic\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <p><a class=\"send_test_notification\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Test desktop notification",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":100,"column":45},"end":{"line":100,"column":79}}}))
    + "</a></p>\n";
},"19":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depths[1],"prefix"),"label":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"settings_label"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":107,"column":20},"end":{"line":107,"column":51}}}),"is_checked":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"settings_object"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":106,"column":25},"end":{"line":106,"column":57}}}),"setting_name":depth0},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"21":function(container,depth0,helpers,partials,data) {
    return "control-label-disabled";
},"23":function(container,depth0,helpers,partials,data) {
    return "              disabled\n              ";
},"25":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                    <option value=\""
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</option>\n";
},"27":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <div class=\"tip\">\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":148,"column":12},"end":{"line":151,"column":19}}})) != null ? stack1 : "")
    + "        </div>\n";
},"28":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                Mobile push notifications are not enabled on this server. <z-link>Learn more</z-link>\n                \n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/mobile-notifications#enabling-push-notifications-for-self-hosted-servers\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"31":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(depths[1],"prefix"),"label":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"settings_label"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":160,"column":20},"end":{"line":160,"column":51}}}),"is_disabled":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"show_push_notifications_tooltip"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":159,"column":26},"end":{"line":159,"column":74}}}),"is_checked":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depths[1],"settings_object"),depth0,{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":158,"column":25},"end":{"line":158,"column":57}}}),"setting_name":depth0},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    var alias1=container.strict, alias2=container.lambda, alias3=container.escapeExpression;

  return "                    <option value=\""
    + alias3(alias2(alias1(depth0, "value", {"start":{"line":181,"column":38},"end":{"line":181,"column":48}} ), depth0))
    + "\">"
    + alias3(alias2(alias1(depth0, "description", {"start":{"line":181,"column":56},"end":{"line":181,"column":72}} ), depth0))
    + "</option>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.strict, alias4=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<form class=\"notification-settings-form\">\n    <div class=\"general_notifications "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":2,"column":38},"end":{"line":2,"column":122}}})) != null ? stack1 : "")
    + "\">\n        <div class=\"subsection-header inline-block\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Notification triggers",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":4,"column":16},"end":{"line":4,"column":46}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":5,"column":104},"end":{"line":5,"column":128}}}),"section_name":"general-notify-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n        <p>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Configure how Zulip notifies you about new messages. In muted streams, stream notification settings apply only to unmuted topics.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":11},"end":{"line":7,"column":149}}}))
    + "</p>\n        <table class=\"notification-table table table-bordered wrapped-table\">\n            <thead>\n                <tr>\n                    <th rowspan=\"2\" width=\"30%\"></th>\n                    <th colspan=\"2\" width=\"28%\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Desktop",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":48},"end":{"line":12,"column":63}}}))
    + "</th>\n                    <th rowspan=\"2\" width=\"14%\">\n                        <span "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"realm_push_notifications_enabled"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":14,"column":36},"end":{"line":14,"column":74}}}),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":30},"end":{"line":14,"column":203}}})) != null ? stack1 : "")
    + ">\n                            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mobile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":28},"end":{"line":15,"column":42}}}))
    + "\n                        </span>\n                        "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/mobile-notifications#enabling-push-notifications-for-self-hosted-servers"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </th>\n                    <th rowspan=\"2\" width=\"14%\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":48},"end":{"line":19,"column":61}}}))
    + "</th>\n                    <th rowspan=\"2\" width=\"14%\">@all\n                        <i class=\"fa fa-question-circle settings-info-icon tippy-zulip-tooltip\" data-tippy-content=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Whether wildcard mentions like @all are treated as mentions for the purpose of notifications.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":116},"end":{"line":21,"column":218}}}))
    + "\"></i>\n                    </th>\n                </tr>\n                <tr>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Visual",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":24},"end":{"line":25,"column":38}}}))
    + "</th>\n                    <th>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Audible",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":24},"end":{"line":26,"column":39}}}))
    + "</th>\n                </tr>\n            </thead>\n            <tbody>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"general_settings"),{"name":"each","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":16},"end":{"line":42,"column":25}}})) != null ? stack1 : "")
    + "            </tbody>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"unless","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":44,"column":12},"end":{"line":47,"column":23}}})) != null ? stack1 : "")
    + "        </table>\n    </div>\n\n    <div class=\"topic_notifications m-10 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":51,"column":41},"end":{"line":51,"column":125}}})) != null ? stack1 : "")
    + "\">\n\n        <div class=\"subsection-header inline-block\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":54,"column":16},"end":{"line":54,"column":44}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/topic-notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":57,"column":109},"end":{"line":57,"column":133}}}),"section_name":"topic-notifications-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <p>\n"
    + ((stack1 = lookupProperty(helpers,"tr").call(alias1,{"name":"tr","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":59,"column":16},"end":{"line":63,"column":23}}})) != null ? stack1 : "")
    + "            </p>\n        </div>\n\n        <div class=\"input-group\">\n            <label for=\"automatically_follow_topics_policy\" class=\"dropdown-title\">"
    + alias2(alias4(alias3(lookupProperty(depth0,"settings_label"), "automatically_follow_topics_policy", {"start":{"line":68,"column":86},"end":{"line":68,"column":135}} ), depth0))
    + "</label>\n            <select name=\"automatically_follow_topics_policy\" class=\"setting_automatically_follow_topics_policy prop-element settings_select bootstrap-focus-style\"\n              id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":70,"column":20},"end":{"line":70,"column":26}} ), depth0))
    + "automatically_follow_topics_policy\" data-setting-widget-type=\"number\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"automatically_follow_topics_policy_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n        <div class=\"input-group\">\n            <label for=\"automatically_unmute_topics_in_muted_streams_policy\" class=\"dropdown-title\">"
    + alias2(alias4(alias3(lookupProperty(depth0,"settings_label"), "automatically_unmute_topics_in_muted_streams_policy", {"start":{"line":76,"column":103},"end":{"line":76,"column":169}} ), depth0))
    + "</label>\n            <select name=\"automatically_unmute_topics_in_muted_streams_policy\" class=\"setting_automatically_unmute_topics_in_muted_streams_policy prop-element settings_select bootstrap-focus-style\"\n              id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":78,"column":20},"end":{"line":78,"column":26}} ), depth0))
    + "automatically_unmute_topics_in_muted_streams_policy\" data-setting-widget-type=\"number\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"automatically_unmute_topics_in_muted_streams_policy_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n        "
    + ((stack1 = container.invokePartial(require("./settings_checkbox.hbs"),depth0,{"name":"settings_checkbox","hash":{"prefix":lookupProperty(lookupProperty(depth0,"notification_settings"),"prefix"),"label":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depth0,"settings_label"),"automatically_follow_topics_where_mentioned",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":86,"column":16},"end":{"line":86,"column":85}}}),"is_checked":lookupProperty(helpers,"lookup").call(alias1,lookupProperty(depth0,"settings_object"),"automatically_follow_topics_where_mentioned",{"name":"lookup","hash":{},"data":data,"loc":{"start":{"line":85,"column":21},"end":{"line":85,"column":91}}}),"setting_name":"automatically_follow_topics_where_mentioned"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"desktop_notifications m-10 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":90,"column":43},"end":{"line":90,"column":127}}})) != null ? stack1 : "")
    + "\">\n\n        <div class=\"subsection-header inline-block\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Desktop message notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":93,"column":16},"end":{"line":93,"column":54}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/desktop-notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":96,"column":105},"end":{"line":96,"column":129}}}),"section_name":"desktop-message-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"unless","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":99,"column":8},"end":{"line":101,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(lookupProperty(depth0,"notification_settings"),"desktop_notification_settings"),{"name":"each","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":103,"column":8},"end":{"line":109,"column":17}}})) != null ? stack1 : "")
    + "\n        <label for=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":111,"column":22},"end":{"line":111,"column":28}} ), depth0))
    + "notification_sound\">\n            "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Notification sound",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":112,"column":12},"end":{"line":112,"column":39}}}))
    + "\n        </label>\n\n        <div class=\"input-group input-element-with-control "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"enable_sound_select"),{"name":"unless","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":59},"end":{"line":115,"column":123}}})) != null ? stack1 : "")
    + "\">\n            <select name=\"notification_sound\" class=\"setting_notification_sound prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":116,"column":138},"end":{"line":116,"column":144}} ), depth0))
    + "notification_sound\" data-setting-widget-type=\"string\"\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"enable_sound_select"),{"name":"unless","hash":{},"fn":container.program(23, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":117,"column":14},"end":{"line":119,"column":25}}})) != null ? stack1 : "")
    + ">\n                <option value=\"none\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"None",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":120,"column":37},"end":{"line":120,"column":50}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(lookupProperty(depth0,"settings_object"),"available_notification_sounds"),{"name":"each","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":121,"column":16},"end":{"line":123,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <span class=\"play_notification_sound\">\n                <i class=\"fa fa-play-circle\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Play sound",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":126,"column":57},"end":{"line":126,"column":76}}}))
    + "\"></i>\n            </span>\n        </div>\n\n        <div class=\"input-group\">\n            <label for=\"desktop_icon_count_display\" class=\"dropdown-title\">"
    + alias2(alias4(alias3(lookupProperty(depth0,"settings_label"), "desktop_icon_count_display", {"start":{"line":131,"column":78},"end":{"line":131,"column":119}} ), depth0))
    + "</label>\n            <select name=\"desktop_icon_count_display\" class=\"setting_desktop_icon_count_display prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":132,"column":154},"end":{"line":132,"column":160}} ), depth0))
    + "desktop_icon_count_display\" data-setting-widget-type=\"number\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"desktop_icon_count_display_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n    </div>\n\n    <div class=\"mobile_notifications m-10 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":138,"column":42},"end":{"line":138,"column":126}}})) != null ? stack1 : "")
    + "\">\n\n        <div class=\"subsection-header inline-block\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Mobile message notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":141,"column":16},"end":{"line":141,"column":53}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/mobile-notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":144,"column":104},"end":{"line":144,"column":128}}}),"section_name":"mobile-message-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"realm_push_notifications_enabled"),{"name":"unless","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":146,"column":8},"end":{"line":153,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(lookupProperty(depth0,"notification_settings"),"mobile_notification_settings"),{"name":"each","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":155,"column":8},"end":{"line":162,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"email_message_notifications m-10 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":165,"column":49},"end":{"line":165,"column":133}}})) != null ? stack1 : "")
    + "\">\n\n        <div class=\"subsection-header inline-block\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Email message notifications",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":168,"column":16},"end":{"line":168,"column":52}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/email-notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":171,"column":103},"end":{"line":171,"column":127}}}),"section_name":"email-message-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"input-group time-limit-setting\">\n\n            <label for=\"email_notifications_batching_period\">\n                "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delay before sending message notification emails",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":177,"column":16},"end":{"line":177,"column":73}}}))
    + "\n            </label>\n            <select name=\"email_notifications_batching_period_seconds\" class=\"setting_email_notifications_batching_period_seconds prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":179,"column":188},"end":{"line":179,"column":194}} ), depth0))
    + "email_notifications_batching_period_seconds\" data-setting-widget-type=\"time-limit\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(depth0,"email_notifications_batching_period_values"),{"name":"each","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":180,"column":16},"end":{"line":182,"column":25}}})) != null ? stack1 : "")
    + "            </select>\n            <div class=\"dependent-settings-block\">\n                <label for=\"email_notification_batching_period_edit_minutes\" class=\"inline-block\">\n                    "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Delay period (minutes)",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":186,"column":20},"end":{"line":186,"column":51}}}))
    + ":\n                </label>\n                <input type=\"text\"\n                  name=\"email_notification_batching_period_edit_minutes\"\n                  class=\"email_notification_batching_period_edit_minutes time-limit-custom-input\"\n                  data-setting-widget-type=\"time-limit\"\n                  autocomplete=\"off\"\n                  id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":193,"column":24},"end":{"line":193,"column":30}} ), depth0))
    + "email_notification_batching_period_edit_minutes\"/>\n            </div>\n        </div>\n\n        <div class=\"input-group\">\n            <label for=\"realm_name_in_email_notifications_policy\" class=\"dropdown-title\">"
    + alias2(alias4(alias3(lookupProperty(depth0,"settings_label"), "realm_name_in_email_notifications_policy", {"start":{"line":198,"column":92},"end":{"line":198,"column":147}} ), depth0))
    + "</label>\n            <select name=\"realm_name_in_email_notifications_policy\" class=\"setting_realm_name_in_email_notifications_policy prop-element settings_select bootstrap-focus-style\" id=\""
    + alias2(alias4(alias3(depth0, "prefix", {"start":{"line":199,"column":182},"end":{"line":199,"column":188}} ), depth0))
    + "realm_name_in_email_notifications_policy\" data-setting-widget-type=\"number\">\n                "
    + ((stack1 = container.invokePartial(require("./dropdown_options_widget.hbs"),depth0,{"name":"dropdown_options_widget","hash":{"option_values":lookupProperty(depth0,"realm_name_in_email_notifications_policy_values")},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </select>\n        </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(lookupProperty(depth0,"notification_settings"),"email_message_notification_settings"),{"name":"each","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":204,"column":8},"end":{"line":210,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"other_email_notifications m-10 "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":213,"column":47},"end":{"line":213,"column":131}}})) != null ? stack1 : "")
    + "\">\n\n        <div class=\"subsection-header inline-block\">\n            <h3>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Other emails",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":216,"column":16},"end":{"line":216,"column":37}}}))
    + "</h3>\n            "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"for_realm_settings"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":217,"column":102},"end":{"line":217,"column":126}}}),"section_name":"other-emails-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,lookupProperty(lookupProperty(depth0,"notification_settings"),"other_email_settings"),{"name":"each","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":219,"column":8},"end":{"line":225,"column":17}}})) != null ? stack1 : "")
    + "    </div>\n</form>\n";
},"12_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-follow"],"data":data,"loc":{"start":{"line":61,"column":20},"end":{"line":61,"column":151}}}) || fn;
  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-unmute"],"data":data,"loc":{"start":{"line":62,"column":20},"end":{"line":62,"column":149}}}) || fn;
  return fn;
  }

,"useDecorators":true,"28_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":150,"column":16},"end":{"line":150,"column":161}}}) || fn;
  return fn;
  }

,"usePartial":true,"useData":true,"useDepths":true});