var Handlebars = require("../../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":12},"end":{"line":7,"column":19}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "                Zulip lets you configure notifications for each topic. You can also automatically follow or unmute topics\n                you start or participate in.\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"tr").call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"tr","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":12},"end":{"line":14,"column":19}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                Zulip lets you mute topics and streams to avoid receiving notifications messages you are not interested in.\n                Muting a stream effectively mutes all topics in that stream. You can also manually mute a topic in an unmuted stream,\n                or unmute a topic in a muted stream. <z-link>Learn more.</z-link>\n                \n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<a href=\"/help/topic-notifications\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"@partial-block"),depth0,{"name":"@partial-block","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</a>";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h3>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Topic settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":38}}}))
    + "\n                "
    + ((stack1 = container.invokePartial(require("../help_link_widget.hbs"),depth0,{"name":"../help_link_widget","hash":{"link":"/help/topic-notifications"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </h3>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <h3>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Topic settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":16},"end":{"line":23,"column":38}}}))
    + "</h3>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div id=\"user-topic-settings\" class=\"settings-section\" data-name=\"topics\">\n    <p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"development"),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":3,"column":8},"end":{"line":15,"column":15}}})) != null ? stack1 : "")
    + "    </p>\n    <div class=\"settings_panel_list_header\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"development"),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.program(10, data, 0, blockParams, depths),"data":data,"loc":{"start":{"line":18,"column":8},"end":{"line":24,"column":15}}})) != null ? stack1 : "")
    + "        "
    + ((stack1 = container.invokePartial(require("./settings_save_discard_widget.hbs"),depth0,{"name":"settings_save_discard_widget","hash":{"show_only_indicator":true,"section_name":"user-topics-settings"},"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        <input id=\"user_topics_search\" class=\"search filter_text_input\" type=\"text\" placeholder=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter topics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":97},"end":{"line":26,"column":119}}}))
    + "\" aria-label=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Filter topics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":133},"end":{"line":26,"column":155}}}))
    + "\"/>\n    </div>\n    <div class=\"progressive-table-wrapper\" data-simplebar>\n        <table class=\"table table-striped wrapped-table\">\n            <thead class=\"table-sticky-headers\">\n                <th data-sort=\"alphabetic\" data-sort-prop=\"stream\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Stream",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":67},"end":{"line":31,"column":82}}}))
    + "</th>\n                <th data-sort=\"alphabetic\" data-sort-prop=\"topic\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Topic",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":66},"end":{"line":32,"column":80}}}))
    + "</th>\n                <th data-sort=\"numeric\" data-sort-prop=\"visibility_policy\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Status",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":75},"end":{"line":33,"column":90}}}))
    + "</th>\n                <th data-sort=\"numeric\" data-sort-prop=\"date_updated\" class=\"active topic_date_updated\">"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Date updated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":34,"column":104},"end":{"line":34,"column":125}}}))
    + "</th>\n            </thead>\n            <tbody id=\"user_topics_table\" data-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"You have not configured any topics yet.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":54},"end":{"line":36,"column":101}}}))
    + "\" data-search-results-empty=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"No topics match your current filter.",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":130},"end":{"line":36,"column":175}}}))
    + "\"></tbody>\n        </table>\n    </div>\n</div>\n";
},"5_d":  function(fn, props, container, depth0, data, blockParams, depths) {

  var decorators = container.decorators, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  fn = lookupProperty(decorators,"inline")(fn,props,container,{"name":"inline","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"args":["z-link"],"data":data,"loc":{"start":{"line":13,"column":16},"end":{"line":13,"column":150}}}) || fn;
  return fn;
  }

,"useDecorators":true,"usePartial":true,"useData":true,"useDepths":true});