var Handlebars = require("../../node_modules/.pnpm/handlebars@4.7.8/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"org-version navbar-dropdown-menu-inner-list-item\">\n                        <a href=\"#about-zulip\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">"
    + container.escapeExpression((((helper = lookupProperty(helpers,"version_display_string") || container.strict(depth0, "version_display_string", {"start":{"line":9,"column":106},"end":{"line":9,"column":128}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"version_display_string","hash":{},"data":data,"loc":{"start":{"line":9,"column":104},"end":{"line":9,"column":130}}}) : helper)))
    + "</a>\n                    </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"server_needs_upgrade"),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":20},"end":{"line":15,"column":27}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"org-upgrade navbar-dropdown-menu-inner-list-item\">\n                        <a href=\"https://zulip.readthedocs.io/en/stable/production/upgrade.html\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Upgrade to the latest release",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":196},"end":{"line":13,"column":234}}}))
    + "</a>\n                    </li>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"org-plan navbar-dropdown-menu-inner-list-item hidden-for-spectators\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_plan_limited"),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":18,"column":24},"end":{"line":26,"column":31}}})) != null ? stack1 : "")
    + "                    </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                            <a href=\"/plans/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">Zulip Cloud Free</a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_plan_standard"),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data,"loc":{"start":{"line":20,"column":24},"end":{"line":26,"column":24}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return "                            <a href=\"/plans/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">Zulip Cloud Standard</a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_plan_standard_sponsored_for_free"),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":22,"column":24},"end":{"line":26,"column":24}}})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    return "                            <a href=\"/plans/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">Zulip Cloud Standard (sponsored)</a>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_plan_plus"),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":24},"end":{"line":26,"column":24}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    return "                            <a href=\"/plans/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">Zulip Cloud Plus</a>\n                        ";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"sponsorship_pending"),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data,"loc":{"start":{"line":30,"column":20},"end":{"line":51,"column":27}}})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"org-upgrade navbar-dropdown-menu-inner-list-item\">\n                        <a href=\"/sponsorship/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Sponsorship request pending",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":147},"end":{"line":32,"column":183}}}))
    + "</a>\n                    </li>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"is_plan_limited"),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":35,"column":20},"end":{"line":39,"column":27}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,lookupProperty(depth0,"is_org_on_paid_plan"),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":20},"end":{"line":50,"column":31}}})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li class=\"org-upgrade navbar-dropdown-menu-inner-list-item\">\n                        <a href=\"/upgrade/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Upgrade to {standard_plan_name}",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":143},"end":{"line":37,"column":183}}}))
    + "</a>\n                    </li>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),lookupProperty(depth0,"is_education_org"),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data,"loc":{"start":{"line":41,"column":24},"end":{"line":49,"column":31}}})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"org-upgrade navbar-dropdown-menu-inner-list-item\">\n                            <a href=\"/sponsorship/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Request education pricing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":151},"end":{"line":43,"column":185}}}))
    + "</a>\n                        </li>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"not").call(alias1,lookupProperty(depth0,"is_business_org"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":45,"column":34},"end":{"line":45,"column":55}}}),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":24},"end":{"line":49,"column":24}}})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                        <li class=\"org-upgrade navbar-dropdown-menu-inner-list-item\">\n                            <a href=\"/sponsorship/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Request sponsorship",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":151},"end":{"line":47,"column":179}}}))
    + "</a>\n                        </li>\n                        ";
},"28":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item navbar-dropdown-menu-inner-list-item hidden-for-spectators\">\n                    <a href=\"#groups\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-user-cog\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Group settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":111},"end":{"line":71,"column":134}}}))
    + "\n                    </a>\n                </li>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item navbar-dropdown-menu-inner-list-item hidden-for-spectators\">\n                    <a href=\"/stats\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-bar-chart\" aria-hidden=\"true\"></i>\n                        <span>"
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Usage statistics",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":30},"end":{"line":84,"column":55}}}))
    + "</span>\n                    </a>\n                </li>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                    <a href=\"/billing/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-credit-card\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Billing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":125,"column":114},"end":{"line":125,"column":130}}}))
    + "\n                    </a>\n                </li>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                    <a href=\"https://zulip.com/help/support-zulip-project\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-heart\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Support Zulip",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":132,"column":108},"end":{"line":132,"column":130}}}))
    + "\n                    </a>\n                </li>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                    <a href=\"/self-hosted-billing/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-rocket\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Plan management",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":139,"column":109},"end":{"line":139,"column":133}}}))
    + "\n                    </a>\n                </li>\n";
},"38":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                    <a href=\"/plans/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-rocket\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Plans and pricing",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":147,"column":109},"end":{"line":147,"column":135}}}))
    + "\n                    </a>\n                </li>\n";
},"40":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"navbar-dropdown-menu-outer-list-item\">\n            <ul class=\"navbar-dropdown-menu-inner-list\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(helpers,"or").call(alias1,lookupProperty(depth0,"can_invite_users_by_email"),lookupProperty(depth0,"can_create_multiuse_invite"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":156,"column":22},"end":{"line":156,"column":79}}}),{"name":"if","hash":{},"fn":container.program(41, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":156,"column":16},"end":{"line":162,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,lookupProperty(depth0,"show_webathena"),{"name":"if","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":163,"column":16},"end":{"line":169,"column":23}}})) != null ? stack1 : "")
    + "                <li class=\"link-item navbar-dropdown-menu-inner-list-item only-visible-for-spectators\">\n                    <a href=\""
    + alias2((((helper = lookupProperty(helpers,"login_link") || container.strict(depth0, "login_link", {"start":{"line":171,"column":31},"end":{"line":171,"column":41}} ))),(typeof helper === "function" ? helper.call(alias1,{"name":"login_link","hash":{},"data":data,"loc":{"start":{"line":171,"column":29},"end":{"line":171,"column":43}}}) : helper)))
    + "\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-log-in\" aria-hidden=\"true\"></i> "
    + alias2(lookupProperty(helpers,"t").call(alias1,"Log in",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":172,"column":109},"end":{"line":172,"column":124}}}))
    + "\n                    </a>\n                </li>\n            </ul>\n        </li>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                    <a tabindex=\"0\" class=\"invite-user-link navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-user-plus\" aria-hidden=\"true\"></i> "
    + container.escapeExpression(lookupProperty(helpers,"t").call(depth0 != null ? depth0 : (container.nullContext || {}),"Invite users",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":159,"column":112},"end":{"line":159,"column":133}}}))
    + "\n                    </a>\n                </li>\n";
},"43":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <li class=\"link-item navbar-dropdown-menu-inner-list-item\" title=\""
    + alias2(lookupProperty(helpers,"t").call(alias1,"Grant Zulip the Kerberos tickets needed to run your Zephyr mirror via Webathena",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":164,"column":82},"end":{"line":164,"column":170}}}))
    + "\" id=\"webathena_login_menu\">\n                    <a href=\"#webathena\" class=\"webathena_login navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon fa fa-bolt\" aria-hidden=\"true\"></i>"
    + alias2(lookupProperty(helpers,"t").call(alias1,"Link with Webathena",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":166,"column":90},"end":{"line":166,"column":118}}}))
    + "\n                    </a>\n                </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.strict, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"navbar-dropdown-menu\" id=\"gear-menu-dropdown\" aria-labelledby=\"settings-dropdown\" data-simplebar>\n    <ul class=\"navbar-dropdown-menu-outer-list\">\n        <li class=\"org-info navbar-dropdown-menu-outer-list-item\">\n            <ul class=\"navbar-dropdown-menu-inner-list\">\n                <li class=\"org-name navbar-dropdown-menu-inner-list-item\">"
    + alias4((((helper = lookupProperty(helpers,"realm_name") || alias1(depth0, "realm_name", {"start":{"line":5,"column":76},"end":{"line":5,"column":86}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"realm_name","hash":{},"data":data,"loc":{"start":{"line":5,"column":74},"end":{"line":5,"column":88}}}) : helper)))
    + "</li>\n                <li class=\"org-url navbar-dropdown-menu-inner-list-item\">"
    + alias4((((helper = lookupProperty(helpers,"realm_url") || alias1(depth0, "realm_url", {"start":{"line":6,"column":75},"end":{"line":6,"column":84}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"realm_url","hash":{},"data":data,"loc":{"start":{"line":6,"column":73},"end":{"line":6,"column":86}}}) : helper)))
    + "</li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"is_self_hosted"),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":7,"column":16},"end":{"line":28,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(helpers,"and").call(alias2,lookupProperty(helpers,"not").call(alias2,lookupProperty(depth0,"is_self_hosted"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":29,"column":27},"end":{"line":29,"column":47}}}),lookupProperty(depth0,"user_has_billing_access"),lookupProperty(helpers,"not").call(alias2,lookupProperty(depth0,"is_plan_standard_sponsored_for_free"),{"name":"not","hash":{},"data":data,"loc":{"start":{"line":29,"column":72},"end":{"line":29,"column":113}}}),{"name":"and","hash":{},"data":data,"loc":{"start":{"line":29,"column":22},"end":{"line":29,"column":114}}}),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":16},"end":{"line":52,"column":23}}})) != null ? stack1 : "")
    + "            </ul>\n        </li>\n        <li class=\"navbar-dropdown-menu-outer-list-item\">\n            <ul class=\"navbar-dropdown-menu-inner-list\">\n                <li class=\"link-item navbar-dropdown-menu-inner-list-item hidden-for-spectators\">\n                    <a href=\"#streams/subscribed\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-hash\" aria-hidden=\"true\"></i> "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Stream settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":107},"end":{"line":59,"column":131}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"link-item navbar-dropdown-menu-inner-list-item admin-menu-item hidden-for-spectators\">\n                    <a href=\"#organization\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-building\" aria-hidden=\"true\"></i>\n                        <span>"
    + alias4(lookupProperty(helpers,"t").call(alias2,"Organization settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":65,"column":30},"end":{"line":65,"column":60}}}))
    + "</span>\n                    </a>\n                </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,lookupProperty(depth0,"is_guest"),{"name":"unless","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":68,"column":16},"end":{"line":74,"column":27}}})) != null ? stack1 : "")
    + "                <li class=\"link-item navbar-dropdown-menu-inner-list-item hidden-for-spectators\">\n                    <a href=\"#settings\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-tool\" aria-hidden=\"true\"></i> "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Personal settings",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":107},"end":{"line":77,"column":133}}}))
    + "\n                    </a>\n                </li>\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias2,lookupProperty(depth0,"is_guest"),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":16},"end":{"line":87,"column":27}}})) != null ? stack1 : "")
    + "                <li class=\"link-item navbar-dropdown-menu-inner-list-item only-visible-for-spectators\">\n                    <a tabindex=\"0\" class=\"change-language-spectator navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-f-globe\" aria-hidden=\"true\"></i> "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Select language",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":110},"end":{"line":90,"column":134}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"link-item navbar-dropdown-menu-inner-list-item gear-menu-select-dark-theme only-visible-for-spectators\">\n                    <a tabindex=\"0\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-moon\" aria-hidden=\"true\"></i> "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Switch to dark theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":95,"column":107},"end":{"line":95,"column":136}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"link-item navbar-dropdown-menu-inner-list-item gear-menu-select-light-theme only-visible-for-spectators\">\n                    <a tabindex=\"0\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-sun\" aria-hidden=\"true\"></i> "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Switch to light theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":100,"column":106},"end":{"line":100,"column":136}}}))
    + "\n                    </a>\n                </li>\n            </ul>\n        </li>\n        <li class=\"hidden-for-spectators navbar-dropdown-menu-outer-list-item\">\n            <ul class=\"navbar-dropdown-menu-inner-list\">\n                <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                    <a href=\""
    + alias4((((helper = lookupProperty(helpers,"apps_page_url") || alias1(depth0, "apps_page_url", {"start":{"line":108,"column":32},"end":{"line":108,"column":45}} ))),(typeof helper === alias3 ? helper.call(alias2,{"name":"apps_page_url","hash":{},"data":data,"loc":{"start":{"line":108,"column":29},"end":{"line":108,"column":48}}}) : helper)))
    + "\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-monitor\" aria-hidden=\"true\"></i> "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Desktop & mobile apps",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":109,"column":110},"end":{"line":109,"column":140}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                    <a href=\"/integrations/\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-git-pull-request\" aria-hidden=\"true\"></i> "
    + alias4(lookupProperty(helpers,"t").call(alias2,"Integrations",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":114,"column":119},"end":{"line":114,"column":140}}}))
    + "\n                    </a>\n                </li>\n                <li class=\"link-item navbar-dropdown-menu-inner-list-item\">\n                    <a href=\"/api\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"navigate-link-on-enter navbar-dropdown-menu-link\">\n                        <i class=\"navbar-dropdown-icon zulip-icon zulip-icon-file-text\" aria-hidden=\"true\"></i> "
    + alias4(lookupProperty(helpers,"t").call(alias2,"API documentation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":119,"column":112},"end":{"line":119,"column":138}}}))
    + "\n                    </a>\n                </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"show_billing"),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":122,"column":16},"end":{"line":128,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"promote_sponsoring_zulip"),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":129,"column":16},"end":{"line":135,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"show_remote_billing"),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":136,"column":16},"end":{"line":142,"column":23}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(depth0,"show_plans"),{"name":"if","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":143,"column":16},"end":{"line":150,"column":23}}})) != null ? stack1 : "")
    + "            </ul>\n        </li>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias2,lookupProperty(helpers,"or").call(alias2,lookupProperty(depth0,"can_invite_users_by_email"),lookupProperty(depth0,"can_create_multiuse_invite"),lookupProperty(depth0,"is_spectator"),lookupProperty(depth0,"show_webathena"),{"name":"or","hash":{},"data":data,"loc":{"start":{"line":153,"column":14},"end":{"line":153,"column":99}}}),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":153,"column":8},"end":{"line":177,"column":15}}})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true});